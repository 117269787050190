import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "view-statistics"
    }}>{`View Statistics`}</h1>
    <p>{`The statistics page displays monthly reports of key statistics that staff can view.`}</p>
    <Alert kind="warning" mdxType="Alert">This functionality is exclusively available to users with Manager or Admin level access permissions.</Alert>
    <h2 {...{
      "id": "view-reports"
    }}>{`View Reports`}</h2>
    <ol>
      <li parentName="ol">{`Select 'Stats' from the left hand menu on the staff view`}</li>
      <li parentName="ol">{`Select the 'Report Month' you wish to view from the drop-down menu`}</li>
      <li parentName="ol">{`Click 'Regenerate Report' to view the statistics for that month`}</li>
    </ol>
    <h2 {...{
      "id": "view-overall--branch-statistics"
    }}>{`View Overall & Branch Statistics`}</h2>
    <ol>
      <li parentName="ol">{`View an organisation overview with key event statistics`}</li>
      <li parentName="ol">{`View how many events are at each location and in which categories`}</li>
      <li parentName="ol">{`View individual location statistics and event feedback`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/OJm5GN-j-hc" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Click any blue button for further information and break downs of stats</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      